/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Text from "./text"
import { useMedia } from "react-use"
import { mediaQueryList } from "../helpers"
import get from "lodash/get"
import queryString from "query-string"
import theme from "../gatsby-plugin-theme-ui/index"

const VideoTour = ({ video, type, title, customStyle,videoTitle }) => {
  const [showContent, setShowContent] = React.useState(false)
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }
  
  React.useEffect(() => {
    if (checkIfWindow) {
      let params = new URLSearchParams(window.location.search);
       let urlCheck = params.get("project-spotlight");
       setShowContent((urlCheck && urlCheck === "true"))
    }
  }, []);

  const play = () => {
    // console.log("play")
    if (!mediaQueryMatch) {
      const win = window.open(video.externalUrl.externalUrl, "_blank")
      win.focus()
    } else {
      setShowContent(true)
    }
  }
  const IFrame = ({ customStyle }) => {
    let url = video.externalUrl.externalUrl
    if (url.includes("youtube.com/")) {
      const id = queryString.parse(url.split("/watch")[1]).v
      url = "https://www.youtube.com/embed/" + id + "?autoplay=1"
    } else if (url.includes("matterport.com/")) {
      url = url + "&play=1&hl=0"
    }
    return (
      <div
        sx={{
          ...customStyle,
        }}
      >
        <iframe
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            mb: 0,
          }}
          title={video.title}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; vr"
          allowFullScreen
        />
      </div>
    )
  }
  const renderPlayButton = () => (
    <div
      sx={{
        width: type === "embedded" ? 96 : 64,
        height: type === "embedded" ? 96 : 64,
        backgroundColor: "primary",
        borderRadius: 9999,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...(type === "mini" && {
          cursor: "pointer",
          ":hover": {
            backgroundColor: "secondary",
          },
        }),
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        class="svg-triangle"
        viewBox={"0 0 100 100"}
        sx={{
          overflow: "visible",
          width: type === "embedded" ? 54 : 36,
          height: type === "embedded" ? 54 : 36,
          fill: "white",
          pl: type === "embedded" ? "9px" : "6px",
        }}
      >
        <path d="M 100,50 0,100 0,0 z" />
      </svg>
    </div>
  )
  const renderThumbnail = () =>
    type !== "mini" ? (
      <button
        onClick={play}
        sx={{
          border: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          background:
            type === "embedded"
              ? "rgba(44,44,44,0.8)"
              : "linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ":hover": {
            background:
              type === "embedded"
                ? "rgba(44,44,44,0.85)"
                : "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)",
          },
        }}
      >
        {renderPlayButton()}
        <Text
          type="h4"
          customStyle={{
            color: "white",
            textAlign:"left",
            ...(type !== "embedded"
              ? {
                  position: "absolute",
                  bottom: 3,
                  left: 3,
                  right:3
                }
              : { mt: [2, 4], fontSize: [null, 24] }),
          }}
        >
          {title || video.title}
        </Text>
      </button>
    ) : (
      <button onClick={play} sx={{ background: "transparent", border: 0,padding:0 }}>
        {videoTitle ? renderCtaButton() :renderPlayButton()}
      </button>
    )
  const renderModal = () => (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        px: 5,
        alignItems: "center",
        zIndex: 9999,
        background: "rgba(0,0,0,0.8)",
      }}
      onClick={() => setShowContent(false)}
      onKeyDown={() => setShowContent(false)}
      role="button"
      tabIndex="0"
    >
      <Text
        type="h3"
        customStyle={{
          width: ["300px", "526px", "718px", "942px", "1030px", "1422px"],
          color: "white",
          textAlign: "right",
          p: "10px",
        }}
        onClick={() => setShowContent(false)}
      >
        <span sx={{ cursor: "pointer" }}>&#10005;</span>
      </Text>
      <div
        sx={{
          width: ["300px", "526px", "718px", "942px", "1030px", "1422px"],
          height: ["168px", "296px", "404px", "530px", "580px", "800px"],
          maxHeight: "90vh",
          position: "relative",
        }}
      >
        <IFrame
          customStyle={{
            width: "100%",
            height: "100%",
            px: 100,
          }}
        />
      </div>
    </div>
  )
  const renderCtaButton =()=>(
    <div
      sx={{
        ...theme.forms.buttonChevron,
        fontSize: 14,
        pr: 7,
        py: "5px",
        pl: 19,
        mt: 26,
        display: "flex",
        justifyContent: "space-between",
        width: 190,
        color: "white",
        background: theme.colors.primary,
        whiteSpace: "nowrap",
        ":hover": {
          background: theme.colors.secondary,
        },
      }}
    >
      {videoTitle}
    </div>
  )
  return type !== "mini" ? (
    <div
      sx={{
        display: "block",
        width: "100%",
        height: 0,
        pb: type === "embedded" ? "55%" : ["55%", "100%"],
        ...(get(video, "thumbnailImage.file.url") && {
          backgroundImage: `url(${video.thumbnailImage.file.url})`,
        }),
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 5,
        position: "relative",
        overflow: "hidden",
        mb: 3,
        ...customStyle,
      }}
    >
      {type === "embedded" ? (
        showContent ? (
          <IFrame
            customStyle={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          />
        ) : (
          renderThumbnail()
        )
      ) : (
        <React.Fragment>
          {showContent && renderModal()}
          {renderThumbnail()}
        </React.Fragment>
      )}
    </div>
  ) : (
    <React.Fragment>
      {showContent && renderModal()}
      {renderThumbnail()}
    </React.Fragment>
  )
}

export default VideoTour
